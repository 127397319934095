import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import { createOrderApi, resumeUploadApi, resumeUploadJdApi } from "../../Api";
import correct from "../../assets/lotties/correct.json";
import Lottie from "lottie-react";
import { CopyOutlined, LoadingOutlined } from "@ant-design/icons";
import ReCAPTCHA from "react-google-recaptcha";
import TextArea from "antd/es/input/TextArea";
import { CloudUpload, MoveLeft, MoveRight } from "lucide-react";
import RazorpayCheckout from "./RazorpayCheckout";
import PaymentOrderModal from "./PaymentOrderModal";

function ResumeUploadModal({
  isModalVisible,
  handleCancel,
  jobId,
  broadcastdata,
  jobData,
}) {
  var FormData = require("form-data");
  var formData = new FormData();
  const MAX_FILE_SIZE = 10 * 1024 * 1024;
  const [reChapthaDisable, setReChapthaDisable] = useState(true);
  const [verified, setVerified] = useState(false);
  const [loading, setLoading] = useState(false);

  const [currentStep, setCurrentStep] = useState(1);

  const [verifiedText, setVerifiedText] = useState(
    "Resume uploaded successfully"
  );
  const [resumeFile, setFileResume] = useState();
  const [answers, setAnswers] = useState(() => {
    // Retrieve answers from storage
    const savedAnswers = localStorage.getItem("resumeUploadAnswers");
    return savedAnswers ? JSON.parse(savedAnswers) : [];
  });
  const [inputData, setInputData] = useState(() => {
    // Retrieve saved data from storage (if available)
    const savedData = localStorage.getItem("resumeUploadInputData");
    return savedData
      ? JSON.parse(savedData)
      : {
          firstName: "",
          lastName: "",
          phoneNo: null,
          email: "",
          msg: "",
        };
  });

  const [errorHandle, setErrorHandle] = useState({
    errorFirstName: "",
    errorLastName: "",
    errorPhoneNo: "",
    errorEmail: "",
    errorFile: "",
  });
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [razorpayModal, setRazorpayModal] = useState(false);
  const [orderModal, setOrderModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [orderData, setOrderData] = useState(null);
  const [paymentSuccess, setPaymentSuccess] = useState(null);

  const handlePaymentChange = (e) => {
    setSelectedPayment(e.target.value);
  };

  const handlePaymentSubmit = () => {
    // console.log(selectedData, selectedData?.paymentCriteriaIdentifier);

    if (
      jobData?.jobApplicationPaymentInfo?.paymentGatewayProviderName ===
      "RAZORPAY"
    ) {
      setLoading(true);
      const options = {
        method: "POST",
        headers: {
          reCaptcha: "string",
        },
        // body: formData,
      };

      let url = `${createOrderApi}?jdIdentifier=${jobId}&paymentCriteriaIdentifier=${selectedData?.paymentIdentifier}`;

      fetch(url, options)
        .then((response) => response.json())
        .then((data) => {
          if (data.resultStatusInfo.resultCode.toLowerCase() === "success") {
            setOrderData(data.data);
            setRazorpayModal(true);
          } else {
            setOrderModal(data.resultStatusInfo.message);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setOrderModal(error.message);
          console.error("Failed to create order id:", error);
        });
    } else {
      setOrderModal("Unsupported payment gateway!!");
      console.warn("Unsupported payment gateway!!");
    }
  };

  useEffect(() => {
    // Save input data and answers to localStorage whenever they change
    localStorage.setItem("resumeUploadInputData", JSON.stringify(inputData));
    localStorage.setItem("resumeUploadAnswers", JSON.stringify(answers));
  }, [inputData, answers]);
  useEffect(() => {
    const savedFileData = localStorage.getItem("resumeFileData");

    if (savedFileData) {
      const parsedFileData = JSON.parse(savedFileData);
      setFileResume(parsedFileData); // Set the saved file data directly to state
    }
  }, []);

  const handleNext = () => {
    const body = {};
    let validationErrors = false;

    body["firstName"] = inputData.firstName;
    body["lastName"] = inputData.lastName;
    body["mobileNumber"] = inputData.phoneNo;
    body["emailId"] = inputData.email;
    body["resumeFile"] = resumeFile;
    if (jobId) {
      for (
        let index = 0;
        index < broadcastdata?.openPoolQuestions?.length;
        index++
      ) {
        const item = broadcastdata.openPoolQuestions[index];
        const answerObj = answers.find(
          (ans) => ans.openPoolQuestionId === item.openPoolQuestionId
        );

        if (item.mandatory && (!answerObj || !answerObj.answer)) {
          validationErrors = true;
          alert("Please attempt all the mandatory questions.");
          return; // Exit the loop and prevent further processing
        }
      }
      body["openPoolAnswers"] = answers;

      body["jdIdentifier"] = jobId;
      broadcastdata?.openPoolQuestions?.forEach((questions) => {
        const existingAnswer = body["openPoolAnswers"].find(
          (answers) =>
            answers.openPoolQuestionId === questions.openPoolQuestionId
        );

        if (!existingAnswer) {
          body["openPoolAnswers"].push({
            openPoolQuestionId: questions.openPoolQuestionId,
            question: questions.question,
            questionType: questions.questionType,
            answer: "",
            isMandatory: questions.mandatory,
          });
        }
      });
    }

    if (inputData.firstName === "" || inputData.firstName === null) {
      setErrorHandle({
        ...errorHandle,
        errorFirstName: "Please provide first name!",
      });
      return;
    } else if (inputData.phoneNo === "" || inputData.phoneNo === null) {
      setErrorHandle({
        ...errorHandle,
        errorPhoneNo: "Please provide phone number!",
      });
      return;
    } else if (inputData.email == "" || inputData.phoneNo == null) {
      setErrorHandle({
        ...errorHandle,
        errorEmail: "Please provide email address!",
      });
      return;
    } else if (!validateEmail(inputData.email)) {
      setErrorHandle({
        ...errorHandle,
        errorEmail: "Invalid email format!",
      });
      return;
    } else if (resumeFile === null || resumeFile === undefined) {
      setErrorHandle({
        ...errorHandle,
        errorFile: "Please upload File!",
      });
      return;
    } else if (validationErrors) {
      return;
    }
    setCurrentStep((prev) => prev + 1);
  };

  const handlePrevious = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const handleChange = (e, name, errorName) => {
    setErrorHandle({
      ...errorHandle,
      [errorName]: " ",
    });

    setInputData({
      ...inputData,
      [name]: e.target.value,
    });
  };

  const handleAnswerChange = (index, value) => {
    setAnswers((prevAnswers) => {
      const updatedAnswers = [...prevAnswers];
      const existingAnswer = updatedAnswers.find(
        (ans) =>
          ans.openPoolQuestionId ===
          broadcastdata.openPoolQuestions[index].openPoolQuestionId
      );

      if (existingAnswer) {
        existingAnswer.answer = value;
      } else {
        updatedAnswers.push({
          openPoolQuestionId:
            broadcastdata.openPoolQuestions[index].openPoolQuestionId,
          question: broadcastdata.openPoolQuestions[index].question,
          questionType: broadcastdata.openPoolQuestions[index].questionType,
          answer: value,
          isMandatory: broadcastdata.openPoolQuestions[index].mandatory,
        });
      }
      return updatedAnswers;
    });
  };
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };
  const handleFileReader = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      if (selectedFile.size <= MAX_FILE_SIZE) {
        setFileResume(selectedFile);

        // Save file metadata to localStorage
        const fileData = {
          name: selectedFile.name,
          size: selectedFile.size,
          type: selectedFile.type,
        };
        localStorage.setItem("resumeFileData", JSON.stringify(fileData));

        setErrorHandle({
          ...errorHandle,
          errorFile: null,
        });
      } else {
        alert("File size exceeds the 10MB limit.");
        event.target.value = null; // Reset the file input
      }
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setFileResume(file);
  };

  const submitApi = () => {
    const body = {};
    let validationErrors = false;

    body["firstName"] = inputData.firstName;
    body["lastName"] = inputData.lastName;
    body["mobileNumber"] = inputData.phoneNo;
    body["emailId"] = inputData.email;
    body["resumeFile"] = resumeFile;
    body["jobApplicationPaymentData"] = paymentSuccess;
    if (jobId) {
      for (
        let index = 0;
        index < broadcastdata?.openPoolQuestions?.length;
        index++
      ) {
        const item = broadcastdata.openPoolQuestions[index];
        const answerObj = answers.find(
          (ans) => ans.openPoolQuestionId === item.openPoolQuestionId
        );

        if (item.mandatory && (!answerObj || !answerObj.answer)) {
          validationErrors = true;
          alert("Please attempt all the mandatory questions.");
          return; // Exit the loop and prevent further processing
        }
      }
      body["openPoolAnswers"] = answers;

      body["jdIdentifier"] = jobId;
      broadcastdata?.openPoolQuestions?.forEach((questions) => {
        const existingAnswer = body["openPoolAnswers"].find(
          (answers) =>
            answers.openPoolQuestionId === questions.openPoolQuestionId
        );

        if (!existingAnswer) {
          body["openPoolAnswers"].push({
            openPoolQuestionId: questions.openPoolQuestionId,
            question: questions.question,
            questionType: questions.questionType,
            answer: "",
            isMandatory: questions.mandatory,
          });
        }
      });
    }
    formData.append("candidateDetailsRequest", JSON.stringify(body));
    formData.append("resumeFile", resumeFile);

    if (inputData.firstName === "" || inputData.firstName === null) {
      setErrorHandle({
        ...errorHandle,
        errorFirstName: "Please provide first name!",
      });
      return;
    } else if (inputData.phoneNo === "" || inputData.phoneNo === null) {
      setErrorHandle({
        ...errorHandle,
        errorPhoneNo: "Please provide phone number!",
      });
      return;
    } else if (inputData.email == "" || inputData.phoneNo == null) {
      setErrorHandle({
        ...errorHandle,
        errorEmail: "Please provide email address!",
      });
      return;
    } else if (!validateEmail(inputData.email)) {
      setErrorHandle({
        ...errorHandle,
        errorEmail: "Invalid email format!",
      });
      return;
    } else if (resumeFile === null || resumeFile === undefined) {
      setErrorHandle({
        ...errorHandle,
        errorFile: "Please upload File!",
      });
      return;
    } else if (validationErrors) {
      return;
    }

    const options = {
      method: "POST", // HTTP method
      headers: {
        reCaptcha: "string", // Add your reCaptcha token here
      },
      body: formData, // Data being sent
    };
    setLoading(true);

    fetch(`${jobId ? resumeUploadJdApi : resumeUploadApi}`, options)
      .then((response) => response.json()) // Parse the JSON response
      .then((data) => {
        if (data.resultStatusInfo.resultCode === "Success") {
          setVerified(true);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error:", error); // Handle any errors
      });
  };
  const handleSubmitFinal = () => {
    const body = {};

    body["firstName"] = inputData.firstName;
    body["lastName"] = inputData.lastName;
    body["mobileNumber"] = inputData.phoneNo;
    body["emailId"] = inputData.email;
    body["resumeFile"] = resumeFile;
    body["jobApplicationPaymentData"] = {
      paymentInfo: paymentSuccess,
      selectedPaymentCriteria: selectedData,
    };

    if (jobId) {
      body["openPoolAnswers"] = answers;

      body["jdIdentifier"] = jobId;
      broadcastdata?.openPoolQuestions?.forEach((questions) => {
        const existingAnswer = body["openPoolAnswers"].find(
          (answers) =>
            answers.openPoolQuestionId === questions.openPoolQuestionId
        );

        if (!existingAnswer) {
          body["openPoolAnswers"].push({
            openPoolQuestionId: questions.openPoolQuestionId,
            question: questions.question,
            questionType: questions.questionType,
            answer: "",
            isMandatory: questions.mandatory,
          });
        }
      });
    }
    formData.append("candidateDetailsRequest", JSON.stringify(body));
    formData.append("resumeFile", resumeFile);
    // console.log(body);

    const options = {
      method: "POST", // HTTP method
      headers: {
        reCaptcha: "string", // Add your reCaptcha token here
      },
      body: formData, // Data being sent
    };
    setLoading(true);

    fetch(`${jobId ? resumeUploadJdApi : resumeUploadApi}`, options)
      .then((response) => response.json()) // Parse the JSON response
      .then((data) => {
        // console.log("Success:", data); // Handle the response data
        if (data.resultStatusInfo.resultCode === "Success") {
          setVerified(true);
          setLoading(false);
        } else {
          setLoading(false);
        }
        // window.location.href="./jobs"
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error:", error); // Handle any errors
      });

    // localStorage.removeItem("resumeFileData");
    // localStorage.removeItem("resumeUploadInputData");
    // localStorage.removeItem("resumeUploadAnswers");
  };
  const handleClose = () => {
    // handleCancel();
    window.location.href = "/jobs";
  };
  function onCaptchaChange(value) {
    setReChapthaDisable(false);
    document.querySelector("#hirePPRecaptcha iframe").style.border = "none";
  }
  //onExpiredChange
  function onExpiredChange(value) {
    document.querySelector("#hirePPRecaptcha iframe").style.border =
      "2px solid red";
    // sessionStorage.setItem("recaptchaFlag", false);
    setReChapthaDisable(true);
  }

  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        message.success("Payment ID copied to clipboard!");
      })
      .catch(() => {
        message.error("Failed to copy Payment ID.");
      });
  };

  return (
    <>
      {orderModal && (
        <PaymentOrderModal
          orderModal={orderModal}
          setOrderModal={setOrderModal}
        />
      )}
      {razorpayModal && (
        <RazorpayCheckout
          apiKey={
            jobData?.jobApplicationPaymentInfo?.paymentGatewayProviderDetails
              ?.apiKey
          }
          selectedData={selectedData}
          orderData={orderData}
          setRazorpayModal={setRazorpayModal}
          setPaymentSuccess={setPaymentSuccess}
        />
      )}
      <Spin
        spinning={loading}
        indicator={
          <LoadingOutlined
            style={{
              fontSize: 24,
            }}
            spin
          />
        }
      >
        <div className="flex flex-wrap items-center gap-y-1 gap-x-2 text-xl font-semibold mb-2">
          <span>Upload Resume </span>
          <span>
            {jobId && (
              <span className="">
                (JobId: <span className="break-all">{jobId}</span>)
              </span>
            )}
          </span>
        </div>

        {verified ? (
          <div className="mt-4 ">
            <Lottie
              className="m-auto w-[80%] h-[100px]"
              animationData={correct}
              loop={true}
              autoplay={true}
            />
            <p className="text-[green] flex justify-center font-semibold">
              {verifiedText}
            </p>
            <Button
              className="w-full mt-4"
              type="primary"
              size="large"
              onClick={handleClose}
            >
              Explore more Jobs
            </Button>
          </div>
        ) : (
          <div className="font-[Inter]">
            {currentStep === 1 && (
              <>
                <Row gutter={[20, 20]} className=" ">
                  <Col md={12} sm={24} xs={24} className="px-1 mt-2">
                    <p className="mb-1">
                      First Name <span style={{ color: "red" }}> *</span>
                    </p>
                    <Input
                      size="large"
                      className={`${
                        errorHandle.errorFirstName.length > 1
                          ? "border-red-500"
                          : ""
                      }`}
                      value={inputData?.firstName}
                      onChange={(e) =>
                        handleChange(e, "firstName", "errorFirstName")
                      }
                    />
                    <p className="text-[red]">{errorHandle.errorFirstName}</p>
                  </Col>
                  <Col md={12} sm={24} xs={24} className="px-1 mt-2">
                    <p className="mb-1">Last Name</p>
                    <Input
                      size="large"
                      onChange={(e) =>
                        handleChange(e, "lastName", "errorLastName")
                      }
                      value={inputData?.lastName}
                    />
                  </Col>
                </Row>

                <Row gutter={[20, 20]} className="mt-2">
                  <Col md={12} sm={24} xs={24} className="px-1 mt-2">
                    <p className="mb-1">
                      Phone Number <span style={{ color: "red" }}> *</span>
                    </p>
                    <Input
                      size="large"
                      className={` ${
                        errorHandle.errorPhoneNo.length > 1
                          ? "border-red-500"
                          : ""
                      }`}
                      onChange={(e) =>
                        handleChange(e, "phoneNo", "errorPhoneNo")
                      }
                      value={inputData?.phoneNo}
                    />
                    <p className="text-[red]"> {errorHandle.errorPhoneNo} </p>
                  </Col>
                  <Col md={12} sm={24} xs={24} className="px-1 mt-2">
                    <p className="mb-1">
                      Email ID <span style={{ color: "red" }}> *</span>
                    </p>
                    <Input
                      size="large"
                      className={` ${
                        errorHandle.errorEmail.length > 1
                          ? "border-red-500"
                          : ""
                      }`}
                      onChange={(e) => handleChange(e, "email", "errorEmail")}
                      value={inputData?.email}
                    />
                    <p className="text-[red]"> {errorHandle.errorEmail} </p>
                  </Col>
                </Row>
                <div
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                  className=" mt-5"
                >
                  {/* <FormGroup> */}
                  <input
                    onChange={handleFileReader}
                    type="file"
                    id="file-zip"
                    accept=".pdf,.doc,.docx"
                    name="uploadZipFile"
                    required
                    className="w-full border-2 mt-5"
                    style={{ display: "none" }}
                  />
                  <label
                    htmlFor="file-zip"
                    className="  rounded-3 p-3 text-center font-semibold"
                    style={{
                      // background: "linear-gradient(45deg, #121C26, #206DC5)",
                      background: "#E8E8E8",
                      borderRadius: "6px",
                      color: "#383838",
                      cursor: "pointer",
                      // border: "none",
                      outline: "none",
                      display: "block",
                      // width:"100%"
                    }}
                  >
                    <div className="flex items-center justify-center gap-4">
                      <CloudUpload size={28} />
                      <p>
                        Upload or Drop, .pdf,doc,docx{" "}
                        <span className="text-red-500">*</span>
                      </p>
                    </div>
                  </label>
                  <div
                    style={{ color: "green" }}
                    className="font-semibold text-center"
                  >
                    {resumeFile?.name}
                  </div>
                  <p className="text-[red]">{errorHandle.errorFile}</p>
                  {/* </FormGroup> */}
                </div>
                {jobId && broadcastdata?.openPoolQuestions?.length > 0 && (
                  <>
                    <Divider className=" p-0 m-0 mt-4 font-bold text-lg text-gray-500 bg-gray-200" />
                    <div className="overflow-y-auto max-h-96 scroll mt-2 pr-1">
                      <p className="font-semibold text-[#101828] text-lg mb-2">
                        Questionnaire (
                        {broadcastdata?.openPoolQuestions?.length})
                      </p>
                      {broadcastdata?.openPoolQuestions?.map((items, index) => (
                        <div key={index} className="my-1 mb-3">
                          <div className="flex items-center gap-1">
                            <p className="font-semibold text-[#475467] mb-2 text-[.98rem]">
                              {index + 1}. {items.question}{" "}
                              {items.mandatory && (
                                <span className="text-red-500">*</span>
                              )}
                            </p>
                          </div>
                          <div>
                            {items.questionType === "SHORT_ANSWER" && (
                              <TextArea
                                placeholder="Write your answer in short..."
                                value={
                                  answers.find(
                                    (ans) =>
                                      ans.openPoolQuestionId ===
                                      items.openPoolQuestionId
                                  )?.answer || ""
                                }
                                onChange={(e) =>
                                  handleAnswerChange(index, e.target.value)
                                }
                              />
                            )}
                            {items.questionType === "NUMERICAL" && (
                              <Input
                                type="number"
                                placeholder="write in numbers..."
                                size="large"
                                value={
                                  answers.find(
                                    (ans) =>
                                      ans.openPoolQuestionId ===
                                      items.openPoolQuestionId
                                  )?.answer || ""
                                }
                                onChange={(e) =>
                                  handleAnswerChange(index, e.target.value)
                                }
                              />
                            )}
                            {items.questionType === "TRUE_OR_FALSE" && (
                              <Checkbox.Group
                                options={["Yes", "No"]}
                                // value={selectedValues}
                                value={
                                  answers.find(
                                    (ans) =>
                                      ans.openPoolQuestionId ===
                                      items.openPoolQuestionId
                                  )?.answer || ""
                                }
                                onChange={(e) => {
                                  const selectedValue =
                                    e.length > 0 ? e[0] : "";
                                  handleAnswerChange(index, selectedValue);
                                }}
                              />
                            )}
                            {items.questionType === "DROPDOWN" && (
                              <Select
                                placeholder="Select an option"
                                className="min-w-[300px]"
                                size="large"
                                value={
                                  answers.find(
                                    (ans) =>
                                      ans.openPoolQuestionId ===
                                      items.openPoolQuestionId
                                  )?.answer || undefined
                                }
                                onChange={(value) =>
                                  handleAnswerChange(index, value)
                                }
                              >
                                {items.options.map((option) => (
                                  <Select.Option key={option} value={option}>
                                    {option}
                                  </Select.Option>
                                ))}
                              </Select>
                            )}
                          </div>
                        </div>
                      ))}
                      <div></div>
                    </div>
                  </>
                )}
                <div
                  className="field flex justify-center mt-3"
                  // type={config.type}
                  style={{
                    width: "100%",
                    fontSize: "1.1rem",
                  }}
                >
                  <ReCAPTCHA
                    className=""
                    id="hirePPRecaptcha"
                    // sitekey="6LctnlkpAAAAAIG2BRLu70wFnmmIlPi6S-ieqaBp"
                    sitekey="6Le2NvMpAAAAAC5haE1Vqh57hmvXgM9pF6sBS3ig"
                    size="invisible"
                    onChange={onCaptchaChange}
                    onExpired={onExpiredChange}
                  />
                </div>
              </>
            )}
            {currentStep === 2 && (
              <div className="mt-5 min-h-48">
                {!paymentSuccess && (
                  <div
                    style={{ whiteSpace: "pre-line" }}
                    className=" font-semibold text-[1.08rem]"
                  >
                    {jobData?.jobApplicationPaymentInfo?.description}
                  </div>
                )}

                <Form>
                  {!paymentSuccess && (
                    <Form.Item
                      label=<div className="font-semibold">
                        Select Payment Criteria
                      </div>
                      required
                      className="mt-5"
                      // style={{ marginBottom: "20px" }}
                    >
                      <Radio.Group
                        value={selectedPayment}
                        onChange={handlePaymentChange}
                        className="flex flex-wrap items-center gap-2 ml-3"
                      >
                        {jobData?.jobApplicationPaymentInfo?.jobApplicationPaymentCriteriaList.map(
                          (item, index) => (
                            <Radio
                              key={index}
                              value={item.description}
                              onChange={() => setSelectedData(item)}
                            >
                              <div className="flex gap-1 flex-wrap">
                                <div>{item.description} -</div>
                                <div className="font-semibold">
                                  {item.currency} {item.amount}
                                </div>
                              </div>
                            </Radio>
                          )
                        )}
                      </Radio.Group>
                    </Form.Item>
                  )}

                  <div className="flex justify-center items-center">
                    {paymentSuccess ? (
                      <Alert
                        className="mt-[2.4rem] px-[1.2rem] py-[1rem]"
                        message={
                          <>
                            <div className="flex items-center justify-center text-[.93rem] font-medium text-center">
                              <span>
                                Your payment was successful! Please save this
                                Payment ID for future reference:
                                <span className="text-[#206DC5] font-semibold">
                                  {" "}
                                  {paymentSuccess?.razorpay_payment_id}{" "}
                                  <Button
                                    type="text"
                                    icon={<CopyOutlined />}
                                    onClick={() =>
                                      handleCopy(
                                        paymentSuccess?.razorpay_payment_id
                                      )
                                    }
                                  ></Button>
                                </span>
                              </span>
                            </div>
                            <div className="mt-[.22rem] text-[.93rem] flex justify-center items-center gap-2 font-medium text-center">
                              <div>
                                <span className="font-bold">Note:</span> If you
                                are facing issues with the payment, please reach
                                out to us at{" "}
                                <a
                                  href="mailto:support@hireplusplus.com"
                                  className="text-[#206DC5] underline font-semibold break-all"
                                >
                                  support@hireplusplus.com
                                </a>{" "}
                                and include your Payment ID in the mail.
                              </div>
                            </div>
                          </>
                        }
                        type="success"
                      />
                    ) : (
                      <Button
                        type="primary"
                        onClick={handlePaymentSubmit}
                        className="mt-3 mx-auto px-8"
                        size="large"
                        disabled={!selectedPayment || selectedData?.amount <= 0}
                      >
                        Pay Now
                      </Button>
                    )}
                  </div>
                </Form>
              </div>
            )}
            {jobData?.jobApplicationPaymentInfo?.paymentRequired &&
            jobData?.jobApplicationPaymentInfo
              ?.jobApplicationPaymentCriteriaList?.length > 0 ? (
              <Row className="mt-3 flex justify-end">
                <Col md={12} sm={12} xs={24} className="px-2 mt-2">
                  {currentStep === 2 && (
                    <Button
                      className="w-full flex justify-center items-center  gap-3"
                      size="large"
                      onClick={handlePrevious}
                    >
                      <MoveLeft /> Previous
                    </Button>
                  )}
                </Col>
                <Col md={12} sm={12} xs={24} className="px-2 mt-2">
                  {currentStep === 1 && (
                    <Button
                      onClick={handleNext}
                      className="w-full flex justify-center items-center  gap-3"
                      type="primary"
                      size="large"
                    >
                      Next <MoveRight />
                    </Button>
                  )}
                  {currentStep === 2 &&
                    (selectedData ? (
                      <Button
                        onClick={handleSubmitFinal}
                        className="w-full"
                        type="primary"
                        size="large"
                        disabled={!paymentSuccess && selectedData?.amount > 0}
                      >
                        Submit
                      </Button>
                    ) : (
                      <Button
                        // onClick={handleSubmitFinal}
                        className="w-full"
                        type="primary"
                        size="large"
                        disabled
                      >
                        Submit
                      </Button>
                    ))}
                </Col>
              </Row>
            ) : (
              <Row className="mt-3 flex justify-end">
                <Col md={12} sm={12} xs={24} className="px-2 mt-2">
                  <Button
                    className="w-full"
                    size="large"
                    type="primary"
                    onClick={submitApi}
                    // disabled={reChapthaDisable}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            )}
          </div>
        )}
      </Spin>
    </>
  );
}

export default ResumeUploadModal;
