import React from "react";
import Icon from "../../assets/icon/Icons";
import "../../styles/mayaPage/scrollSection.css";

const features = [
  {
    icon: "maya-features-logo1",
    title: "Comprehensive Test Library",
    content: "Access pre-built assessments or create custom tests.",
  },
  {
    icon: "maya-features-logo2",
    title: "Custom Branding",
    content: "Tailor the interview experience to your organization.",
  },
  {
    icon: "maya-features-logo3",
    title: "API Integration",
    content: "Seamlessly integrate with existing HR systems.",
  },
  {
    icon: "maya-features-logo4",
    title: "Dedicated Support",
    content:
      "Provides expert guidance and support throughout the entire process.",
  },
  {
    icon: "maya-features-logo5",
    title: "Secure, Cheat-proof Proctoring",
    content: "Ensures the integrity and reliability of the assessment process.",
  },
];

function ScrollSection() {
  return (
    <div className="text-center pt-6 pb-[3.6rem] px-3">
      <div className="text-[#206DC5] font-semibold text-[1.2rem]">Our USP</div>
      <div className="font-bold text-[#101828] text-[1.6rem] mt-[.3rem] leading-[2.2rem]">
        Additional features
      </div>
      <div className="mt-[2.1rem] w-full overflow-hidden">
        <div
          className="scroll-container"
          style={{
            width: `calc(${features.length} * 12.3rem * 2)`, // Adjust for mobile width and duplication
          }}
        >
          {/* Duplicate the features array to create the continuous effect */}
          {[...features, ...features].map((feature, index) => (
            <div key={index} className="flex flex-col gap-[.35rem]">
              <div>
                <Icon type={feature.icon} />
              </div>
              <div className="px-1 font-semibold text-[#101828] mt-[.36rem]">
                {feature.title}
              </div>
              <div className="px-1 text-[#475467] w-[95%] text-[.94rem]">
                {feature.content}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ScrollSection;
