import React, { useEffect, useState } from "react";
import { Dropdown, Select, Space, Menu } from "antd";
import { Link, NavLink } from "react-router-dom";
import Icon from "../assets/logo/hire-topbar-logo.svg";
import IconLogo from "../assets/icon/Icons";
import English from "../utils/language/English.json";
import French from "../utils/language/French.json";
import German from "../utils/language/German.json";
import { languageChange } from "../utils/language/languageFunc";
import { DownOutlined } from "@ant-design/icons";

function Topbar(props) {
  const [currLangJSON, setCurrLangJSON] = useState(English);
  const [currLang, setCurrLang] = useState("english");

  useEffect(() => {
    const storedLang = localStorage.getItem("hirePPCurrLang") || "english";
    setCurrLang(storedLang);
    props.globalLang(storedLang);
    fetchPrevLangData(storedLang);
  }, []);

  const fetchPrevLangData = (storedLang) => {
    if (storedLang === "english") {
      setCurrLangJSON(English);
    } else if (storedLang === "french") {
      setCurrLangJSON(French);
    } else if (storedLang === "german") {
      setCurrLangJSON(German);
    }
  };

  const currLangChange = (newLang) => {
    setCurrLang(newLang);
    localStorage.setItem("hirePPCurrLang", newLang);
    props.globalLang(newLang);
    fetchPrevLangData(newLang);
  };

  const handleLangChange = (e) => {
    languageChange(e);
    currLangChange(e);
  };

  const itemsLang = [
    { value: "english", label: "English" },
    { value: "german", label: "German" },
    { value: "french", label: "French" },
  ];

  const resourceItems = {
    items: [
      {
        key: "1",
        label: (
          <Link
            to="/about-us"
            className="flex items-start gap-3 mt-2 mb-2 px-1"
          >
            <svg
              className="mt-[6px]"
              width="20"
              height="20"
              viewBox="0 0 22 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.9461 13.8369C18.402 14.5683 19.6503 15.742 20.5614 17.2096C20.7418 17.5003 20.832 17.6456 20.8632 17.8468C20.9266 18.2558 20.6469 18.7585 20.2661 18.9204C20.0786 19 19.8678 19 19.4461 19M14.9461 9.53224C16.4278 8.79589 17.4461 7.26686 17.4461 5.5C17.4461 3.73314 16.4278 2.20411 14.9461 1.46776M12.9461 5.5C12.9461 7.98528 10.9314 10 8.44613 10C5.96084 10 3.94613 7.98528 3.94613 5.5C3.94613 3.01472 5.96084 1 8.44613 1C10.9314 1 12.9461 3.01472 12.9461 5.5ZM1.50536 16.9383C3.09966 14.5446 5.6155 13 8.44613 13C11.2768 13 13.7926 14.5446 15.3869 16.9383C15.7362 17.4628 15.9108 17.725 15.8907 18.0599C15.875 18.3207 15.7041 18.64 15.4957 18.7976C15.228 19 14.86 19 14.1238 19H2.76848C2.0323 19 1.6642 19 1.39656 18.7976C1.18817 18.64 1.01721 18.3207 1.00156 18.0599C0.981454 17.725 1.15609 17.4628 1.50536 16.9383Z"
                stroke="#206DC5"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div>
              <div className="text-[#101828] font-semibold text-[15px]">
                About us
              </div>
              <div className="text-[#475467] text-sm">
                Learn more about our story.
              </div>
            </div>
          </Link>
        ),
      },
      {
        key: "2",
        label: (
          <Link
            to="https://blog.hireplusplus.com/"
            target="_blank"
            className="flex items-start gap-3 mt-2 mb-2 px-1"
          >
            <svg
              className="mt-[6px]"
              width="20"
              height="20"
              viewBox="0 0 18 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17 18V15H4C2.34315 15 1 16.3431 1 18M5.8 21H13.8C14.9201 21 15.4802 21 15.908 20.782C16.2843 20.5903 16.5903 20.2843 16.782 19.908C17 19.4802 17 18.9201 17 17.8V4.2C17 3.07989 17 2.51984 16.782 2.09202C16.5903 1.71569 16.2843 1.40973 15.908 1.21799C15.4802 1 14.9201 1 13.8 1H5.8C4.11984 1 3.27976 1 2.63803 1.32698C2.07354 1.6146 1.6146 2.07354 1.32698 2.63803C1 3.27976 1 4.11984 1 5.8V16.2C1 17.8802 1 18.7202 1.32698 19.362C1.6146 19.9265 2.07354 20.3854 2.63803 20.673C3.27976 21 4.11984 21 5.8 21Z"
                stroke="#206DC5"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div>
              <div className="text-[#101828] font-semibold text-[15px]">
                Blog
              </div>
              <div className="text-[#475467] text-sm">
                The latest industry news, updates and info.
              </div>
            </div>
          </Link>
        ),
      },
    ],
  };

  const productItems = {
    items: [
      {
        key: "1",
        label: (
          <Link to="/" className="flex items-start gap-3 mt-2 mb-2 px-1">
            <svg
              className="mt-[2px]"
              width="26"
              height="26"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M23 23L17.6833 17.6833M20.5556 10.7778C20.5556 16.1779 16.1779 20.5556 10.7778 20.5556C5.37766 20.5556 1 16.1779 1 10.7778C1 5.37766 5.37766 1 10.7778 1C16.1779 1 20.5556 5.37766 20.5556 10.7778Z"
                stroke="#206DC5"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.88867 15.3351C7.18633 13.9587 8.94813 13.1128 10.8887 13.1128C12.8292 13.1128 14.591 13.9587 15.8887 15.3351M13.3887 8.39063C13.3887 9.77134 12.2694 10.8906 10.8887 10.8906C9.50796 10.8906 8.38867 9.77134 8.38867 8.39063C8.38867 7.00991 9.50796 5.89062 10.8887 5.89062C12.2694 5.89062 13.3887 7.00991 13.3887 8.39063Z"
                stroke="#206DC5"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div className="flex justify-between w-full">
              <div>
                <div className="text-[#101828] font-semibold text-[15px]">
                  Hire++ Co-Pilot
                </div>
                <div className="text-[#475467] text-sm">
                  Helping you optimize and save your time assessing Applicants
                </div>
              </div>
              <span className="mt-1">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 14L14 4M14 4H4M14 4V14"
                    stroke="#206DC5"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
          </Link>
        ),
      },
      {
        key: "2",
        label: (
          <Link
            to="/ai-recruiter"
            className="flex items-start gap-3 mt-2 mb-2 px-1"
          >
            <svg
              className="mt-[2px]"
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.5 12L6.28446 13.5689C6.54995 14.0999 6.68269 14.3654 6.86003 14.5954C7.01739 14.7996 7.20041 14.9826 7.40455 15.14C7.63462 15.3173 7.9001 15.4501 8.43108 15.7155L10 16.5L8.43108 17.2845C7.9001 17.5499 7.63462 17.6827 7.40455 17.86C7.20041 18.0174 7.01739 18.2004 6.86003 18.4046C6.68269 18.6346 6.54995 18.9001 6.28446 19.4311L5.5 21L4.71554 19.4311C4.45005 18.9001 4.31731 18.6346 4.13997 18.4046C3.98261 18.2004 3.79959 18.0174 3.59545 17.86C3.36538 17.6827 3.0999 17.5499 2.56892 17.2845L1 16.5L2.56892 15.7155C3.0999 15.4501 3.36538 15.3173 3.59545 15.14C3.79959 14.9826 3.98261 14.7996 4.13997 14.5954C4.31731 14.3654 4.45005 14.0999 4.71554 13.5689L5.5 12Z"
                stroke="#206DC5"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M14 1L15.1786 4.06442C15.4606 4.79765 15.6016 5.16426 15.8209 5.47264C16.0153 5.74595 16.254 5.98475 16.5274 6.17909C16.8357 6.39836 17.2024 6.53937 17.9356 6.82138L21 8L17.9356 9.17862C17.2024 9.46063 16.8357 9.60164 16.5274 9.82091C16.254 10.0153 16.0153 10.254 15.8209 10.5274C15.6016 10.8357 15.4606 11.2024 15.1786 11.9356L14 15L12.8214 11.9356C12.5394 11.2024 12.3984 10.8357 12.1791 10.5274C11.9847 10.254 11.746 10.0153 11.4726 9.82091C11.1643 9.60164 10.7976 9.46063 10.0644 9.17862L7 8L10.0644 6.82138C10.7976 6.53937 11.1643 6.39836 11.4726 6.17909C11.746 5.98475 11.9847 5.74595 12.1791 5.47264C12.3984 5.16426 12.5394 4.79765 12.8214 4.06442L14 1Z"
                stroke="#206DC5"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <div className="flex justify-between w-full">
              <div>
                <div className="text-[#101828] font-semibold text-[15px]">
                  AI Recruiter
                </div>
                <div className="text-[#475467] text-sm">
                  Revolutionize your hiring process with AI-powered interviews,
                  assessments, and insights.
                </div>
              </div>
              <span className="mt-1">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 14L14 4M14 4H4M14 4V14"
                    stroke="#206DC5"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
          </Link>
        ),
      },
      {
        key: "3",
        label: (
          <Link
            to="/ai-parser"
            className="flex items-start gap-3 mt-2 mb-2 px-1"
          >
            <svg
              className=""
              width="26"
              height="26"
              viewBox="0 0 25 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 14.5V8.8C20 7.11984 20 6.27976 19.673 5.63803C19.3854 5.07354 18.9265 4.6146 18.362 4.32698C17.7202 4 16.8802 4 15.2 4H8.8C7.11984 4 6.27976 4 5.63803 4.32698C5.07354 4.6146 4.6146 5.07354 4.32698 5.63803C4 6.27976 4 7.11984 4 8.8V19.2C4 20.8802 4 21.7202 4.32698 22.362C4.6146 22.9265 5.07354 23.3854 5.63803 23.673C6.27976 24 7.11984 24 8.8 24H12M14 13H8M10 17H8M16 9H8M14.5 21L16.5 23L21 18.5"
                stroke="#206DC5"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div className="flex justify-between w-full">
              <div>
                <div className="text-[#101828] font-semibold text-[15px]">
                  Parse++ AI Parsing
                </div>
                <div className="text-[#475467] text-sm">
                  Achieve precise and efficient parsing and matching of job
                  descriptions and CVs
                </div>
              </div>
              <span className="mt-1">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 14L14 4M14 4H4M14 4V14"
                    stroke="#206DC5"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
          </Link>
        ),
      },
    ],
  };

  return (
    <>
      <div
        className="topbar"
        style={{ maxWidth: "2160px", right: "auto", left: "auto" }}
      >
        <div className="back-cover"></div>
        <Link className="hire-plus-logo" to="/" style={{ cursor: "pointer" }}>
          <img src={Icon} alt="logo" width={110} />
        </Link>
        <div className="right-section">
          <div className="nav-menu dropdown">
            <Dropdown
              menu={resourceItems}
              trigger={["click"]}
              dropdownRender={(menu) => (
                <div className="bg-white w-[320px] shadow-lg rounded-lg mt-3 border border-[#EAECF0]">
                  {menu}
                </div>
              )}
            >
              <Space className="weServe-text">Resources</Space>
            </Dropdown>
          </div>
          <div className="toggle-options" style={{ display: "flex" }}>
            <div
              className="nav-menu"
              data-status={props.page === "maya" ? true : false}
            >
              <Link to="/ai-recruiter" className="topbard-text">
                AI Recruiter
              </Link>
            </div>
            <div
              className="nav-menu"
              data-status={props.page === "main" ? true : false}
            >
              <Link to="/" className="topbard-text">
                {currLangJSON["topbar.employers"]}
              </Link>
            </div>
            <div
              className="nav-menu"
              data-status={props.page === "ai-parser" ? true : false}
            >
              <Link to="/ai-parser" className="topbard-text flex gap-2">
                <IconLogo type={"Ai-topar"} /> {currLangJSON["topbar.aiParser"]}
              </Link>
            </div>

            <div
              className="nav-menu"
              data-status={props.page === "jobs" ? true : false}
            >
              <Link to="/jobs" className="topbard-text">
                {currLangJSON["topbar.jobs"]}
              </Link>
            </div>
            <div
              className="nav-menu"
              data-status={props.page === "resource" ? true : false}
            >
              <Dropdown
                menu={resourceItems}
                trigger={["click"]}
                dropdownRender={(menu) => (
                  <div className="bg-white w-[320px] shadow-lg rounded-lg mt-3 border border-[#EAECF0]">
                    {menu}
                  </div>
                )}
              >
                <a
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  <Space className="topbard-text">
                    {currLangJSON["topbar.resources"]}
                    <DownOutlined />
                  </Space>
                </a>
              </Dropdown>
            </div>
            <div
              className="nav-menu"
              data-status={props.page === "pricing" ? true : false}
            >
              <Link to="/pricing" className="topbard-text">
                {currLangJSON["topbar.pricing"]}
              </Link>
            </div>
            <div
              className="nav-menu"
              data-status={props.page === "product" ? true : false}
            >
              <Dropdown
                menu={productItems}
                trigger={["click"]}
                dropdownRender={(menu) => (
                  <div className="bg-white w-[420px] shadow-lg rounded-lg mt-3 border border-[#EAECF0]">
                    {menu}
                  </div>
                )}
              >
                <a
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  <Space className="topbard-text">
                    Products <DownOutlined />{" "}
                  </Space>
                </a>
              </Dropdown>
            </div>
          </div>
          {/* <Select
            className="ml-5 mr-0"
            defaultValue="English"
            style={{ width: 100 }}
            onChange={handleLangChange}
            options={itemsLang}
            value={currLang}
          /> */}
          <div className="nav-menu hollow-button">
            <Link to="/access-page" style={{ color: "grey" }}>
              Log In
            </Link>
          </div>
          <div
            className="nav-menu bg-[#103662] px-3 contact-option"
            style={{ borderRadius: "2px" }}
          >
            <a href="/contact-us">Contact Us</a>
          </div>
        </div>
      </div>
      {/* <p className="lang-mobile">
        <Select
          className="mr-0"
          defaultValue="EN"
          onChange={handleLangChange}
          options={itemsLang}
          value={currLang}
        />
      </p> */}
      <input type="checkbox" id="active" />
      <label htmlFor="active" className="menu-btn">
        <i className="fas fa-bars"></i>
      </label>
      <div className="topbar-logo">
        <Link className="logo" to="/">
          <img src={Icon} alt="hireplusplus-logo" className="img-logo" />
        </Link>
      </div>
      <div className="wrapper">
        <ul className="mt-8">
          <li className="">
            <NavLink to="/ai-recruiter" activeClassName="active">
              <svg
                width="27"
                height="27"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ display: "inline-block", marginRight: "14px" }}
              >
                <path
                  d="M5.5 12L6.28446 13.5689C6.54995 14.0999 6.68269 14.3654 6.86003 14.5954C7.01739 14.7996 7.20041 14.9826 7.40455 15.14C7.63462 15.3173 7.9001 15.4501 8.43108 15.7155L10 16.5L8.43108 17.2845C7.9001 17.5499 7.63462 17.6827 7.40455 17.86C7.20041 18.0174 7.01739 18.2004 6.86003 18.4046C6.68269 18.6346 6.54995 18.9001 6.28446 19.4311L5.5 21L4.71554 19.4311C4.45005 18.9001 4.31731 18.6346 4.13997 18.4046C3.98261 18.2004 3.79959 18.0174 3.59545 17.86C3.36538 17.6827 3.0999 17.5499 2.56892 17.2845L1 16.5L2.56892 15.7155C3.0999 15.4501 3.36538 15.3173 3.59545 15.14C3.79959 14.9826 3.98261 14.7996 4.13997 14.5954C4.31731 14.3654 4.45005 14.0999 4.71554 13.5689L5.5 12Z"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14 1L15.1786 4.06442C15.4606 4.79765 15.6016 5.16426 15.8209 5.47264C16.0153 5.74595 16.254 5.98475 16.5274 6.17909C16.8357 6.39836 17.2024 6.53937 17.9356 6.82138L21 8L17.9356 9.17862C17.2024 9.46063 16.8357 9.60164 16.5274 9.82091C16.254 10.0153 16.0153 10.254 15.8209 10.5274C15.6016 10.8357 15.4606 11.2024 15.1786 11.9356L14 15L12.8214 11.9356C12.5394 11.2024 12.3984 10.8357 12.1791 10.5274C11.9847 10.254 11.746 10.0153 11.4726 9.82091C11.1643 9.60164 10.7976 9.46063 10.0644 9.17862L7 8L10.0644 6.82138C10.7976 6.53937 11.1643 6.39836 11.4726 6.17909C11.746 5.98475 11.9847 5.74595 12.1791 5.47264C12.3984 5.16426 12.5394 4.79765 12.8214 4.06442L14 1Z"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <span className="text-[1.2rem]">AI Recruiter</span>
            </NavLink>
          </li>
          <li className="">
            <NavLink to="/" activeClassName="active">
              <IconLogo type={"employer"} />
              <span className="text-[1.2rem]">
                {currLangJSON["topbar.employers"]}
              </span>
            </NavLink>
          </li>
          <li className="">
            <NavLink to="/ai-parser" activeClassName="active">
              <svg
                width="27"
                height="27"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ display: "inline-block", marginRight: "13px" }}
              >
                <g clip-path="url(#clip0_1759_7897)">
                  <path
                    d="M3.74984 18.3346V14.168M3.74984 5.83464V1.66797M1.6665 3.7513H5.83317M1.6665 16.2513H5.83317M10.8332 2.5013L9.38802 6.25869C9.15301 6.86971 9.03551 7.17523 8.85278 7.43221C8.69083 7.65997 8.49184 7.85896 8.26408 8.02091C8.00709 8.20364 7.70158 8.32114 7.09055 8.55615L3.33317 10.0013L7.09056 11.4465C7.70158 11.6815 8.00709 11.799 8.26408 11.9817C8.49184 12.1436 8.69083 12.3426 8.85278 12.5704C9.03551 12.8274 9.15301 13.1329 9.38802 13.7439L10.8332 17.5013L12.2783 13.7439C12.5133 13.1329 12.6308 12.8274 12.8136 12.5704C12.9755 12.3426 13.1745 12.1436 13.4023 11.9817C13.6592 11.799 13.9648 11.6815 14.5758 11.4464L18.3332 10.0013L14.5758 8.55615C13.9648 8.32114 13.6592 8.20364 13.4023 8.02091C13.1745 7.85896 12.9755 7.65997 12.8136 7.43221C12.6308 7.17523 12.5133 6.86971 12.2783 6.25869L10.8332 2.5013Z"
                    stroke="#000"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    style={{
                      stroke: "#000",
                      transition: "all 0.2s ease-in-out 0s",
                    }}
                  ></path>
                </g>
                <defs>
                  <clipPath id="clip0_1759_7897">
                    <rect width="20" height="20" fill="white"></rect>
                  </clipPath>
                </defs>
              </svg>
              <span className="text-[1.2rem]">
                {currLangJSON["topbar.aiParser"]}
              </span>
            </NavLink>
          </li>

          <li>
            <NavLink to="/jobs" activeClassName="active">
              <IconLogo type={"job"} />
              <span className="text-[1.2rem]">
                {currLangJSON["topbar.jobs"]}
              </span>
            </NavLink>
          </li>
          <li>
            <Dropdown
              menu={resourceItems}
              trigger={["click"]}
              dropdownRender={(menu) => (
                <div className="bg-white w-[320px] shadow-lg rounded-lg ml-4 mt-1 border border-[#EAECF0]">
                  {menu}
                </div>
              )}
            >
              <a
                className="ant-dropdown-link d-flex nav-link-svg align-items-center"
                onClick={(e) => e.preventDefault()}
              >
                <IconLogo type={"resource"} />
                <span className="text-[1.2rem]">
                  {currLangJSON["topbar.resources"]}
                </span>
              </a>
            </Dropdown>
          </li>
          <li>
            <NavLink
              to="/pricing"
              // activeClassName="active"
            >
              <IconLogo type={"pricing"} />
              <span className="text-[1.2rem]">
                {currLangJSON["topbar.pricing"]}
              </span>
            </NavLink>
          </li>
          <li>
            <Dropdown
              menu={productItems}
              trigger={["click"]}
              dropdownRender={(menu) => (
                <div className="bg-white w-[420px] shadow-lg rounded-lg mt-1 ml-4 border border-[#EAECF0]">
                  {menu}
                </div>
              )}
            >
              <a
                className="ant-dropdown-link d-flex nav-link-svg align-items-center"
                onClick={(e) => e.preventDefault()}
              >
                <IconLogo type={"resource"} />
                <span className="text-[1.2rem]">Products</span>
              </a>
            </Dropdown>
          </li>
        </ul>

        <div className="top-sec-topbar">
          <img src={Icon} alt="logo" />
        </div>
        <div className="bottom-sec-topbar">
          <NavLink activeClassName="active" to="/access-page">
            <p className="text-[black] text-[1.2rem] font-semibold mb-4">
              Log In
            </p>
          </NavLink>
          <NavLink to="/contact-us" activeClassName="active">
            <p className="text-[black] text-[1.2rem] font-semibold">
              Contact Us
            </p>
          </NavLink>
        </div>
      </div>
    </>
  );
}

export default Topbar;
