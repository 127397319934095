import { Card, Divider, Dropdown, Spin, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Icon from "../../assets/icon/Icons";
import PdfViewer from "../aiParser/aiPdfView";
import {
  MinusCircleOutlined,
  PlusCircleOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import { getBroadcastJdData } from "../../Api";
import ResumeUploadModal from "./ResumeUploadModal";
import Lottie from "lottie-react";
import jdNotFound from "../../assets/lotties/JD-notFound.json";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

const shareUrl = window.location.href;
const shareTitle = "Check out this job!";

function ViewJobLandingPage() {
  const urlParams = new URLSearchParams(window.location.search);
  const openFlag = urlParams.get("open");
  const { jobId } = useParams();
  const [zoom, setZoom] = useState(1);
  const [loading, setLoading] = useState(false);
  const [job, setJobData] = useState(null);
  const [pdfPages, setPdfPages] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [jobPaymentFlag, setJobPaymentFlag] = useState(openFlag ? true : false);

  const cardRef = useRef(null);

  useEffect(() => {
    if (cardRef.current && !loading) {
      cardRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [loading]);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const callBroadcastApi = async () => {
    setLoading(true);
    let api = `${getBroadcastJdData}?jdIdentifier=${jobId}`;
    try {
      const response = await fetch(api);
      const data = await response.json();
      if (data.resultStatusInfo.resultCode === "Success") {
        setJobData(data.data);
      } else if (data.resultStatusInfo.resultCode === "NotFound") {
        setJobData(null);
      } else {
        console.error("Could not retrieve job");
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching job:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    callBroadcastApi();
    localStorage.removeItem("resumeFileData");
    localStorage.removeItem("resumeUploadInputData");
    localStorage.removeItem("resumeUploadAnswers");
  }, []);

  const converToReadableDate = (value) => {
    const preferredJoiningDate = value;
    let readableDate = "";
    if (preferredJoiningDate) {
      const date = new Date(preferredJoiningDate);
      const options = { year: "numeric", month: "long", day: "numeric" };
      readableDate = date.toLocaleDateString("en-US", options);
    }
    return readableDate;
  };

  const handleZoomIn = () => {
    setZoom(zoom + 0.2); // Increase zoom by 1.5 times
  };
  const handleZoomOut = () => {
    setZoom(zoom - 0.2); // Decrease zoom by 1.5 times
  };

  const handleCopyLink = () => {
    const currentUrl = window.location.href;
    navigator.clipboard
      .writeText(currentUrl)
      .then(() => {
        message.success("Link copied!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const shareMenu = [
    {
      label: (
        <div onClick={handleCopyLink} className="font-[Inter]">
          Copy share link
        </div>
      ),
      key: "0",
    },
    {
      label: (
        <FacebookShareButton
          url={shareUrl}
          quote={shareTitle}
          className="social-share-button"
        >
          {/* <FacebookIcon size={20} round />  */}
          <p className="font-[Inter]">Facebook</p>
        </FacebookShareButton>
      ),
      key: "1",
    },
    {
      label: (
        <TwitterShareButton
          url={shareUrl}
          title={shareTitle}
          className="social-share-button"
        >
          {/* <TwitterIcon size={32} round />  */}
          <p className="font-[Inter]">Twitter</p>
        </TwitterShareButton>
      ),
      key: "2",
    },
    {
      label: (
        <LinkedinShareButton
          url={shareUrl}
          title={shareTitle}
          className="social-share-button"
        >
          {/* <LinkedinIcon size={32} round />  */}
          <p className="font-[Inter]">LinkedIn</p>
        </LinkedinShareButton>
      ),
      key: "3",
    },
    {
      label: (
        <WhatsappShareButton
          url={shareUrl}
          title={shareTitle}
          className="social-share-button"
        >
          <p className="font-[Inter]">WhatsApp</p>
        </WhatsappShareButton>
      ),
      key: "4",
    },
  ];

  return (
    <>
      <div className="py-24 px-4 lg:px-12">
        <Spin spinning={loading} size="large" className="mt-12">
          {loading ? (
            <div className="text-center mt-8 text-gray-400 text-sm">
              Loading...
            </div>
          ) : job ? (
            <>
              <Card className="rounded-2xl shadow-sm font-[Inter] border border-[#e3e3e6] w-[95%] lg:w-[70%] mt-5 mx-auto">
                <div className="flex gap-3 justify-between items-start">
                  <div className="w-[92%] flex justify-between flex-wrap gap-3 items-start">
                    <div className="font-semibold text-base text-[#101828] flex gap-3">
                      <div className="flex gap-2 items-start flex-wrap">
                        <div>
                          {job.title
                            .split(" ")
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() + word.slice(1)
                            )
                            .join(" ")}
                        </div>
                        <div className="bg-[#ECFDF3] rounded-2xl border border-[#ABEFC6] px-[10px] text-[0.73rem] text-[#067647] flex gap-2 items-center">
                          <svg
                            width="6"
                            height="7"
                            viewBox="0 0 6 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="3" cy="3.25" r="3" fill="#17B26A" />
                          </svg>
                          {job.jobDomain}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-wrap gap-2 items-center">
                      <div className=" font-semibold text-[13px] bg-[#eaf3fe] rounded-2xl border border-[#eaf3fe] px-[10px] text-[#185294]">
                        <span className="">{job.numberOfOpenings}</span>
                        <span className=""> Openings</span>
                      </div>
                      <div className=" font-semibold text-[13px]  bg-[#ECFDF3] rounded-2xl border border-[#ABEFC6] px-[10px] text-[#067647]">
                        <span className="">{job.totalActiveApplicants}</span>
                        <span> Applications</span>
                      </div>
                    </div>
                  </div>
                  <Dropdown
                    menu={{
                      items: shareMenu,
                    }}
                    trigger={["click"]}
                  >
                    <div className="text-[15px] text-[#475467] flex items-center gap-[5px] cursor-pointer">
                      <ShareAltOutlined />
                      <p className="text-sm">Share</p>
                    </div>
                  </Dropdown>
                </div>
                {job.interviewRoomSkillList ? (
                  <div className="flex flex-wrap gap-2 mt-6 lg:mt-[12px]">
                    {job.interviewRoomSkillList.map((skill) => (
                      <div className="px-3 py-[3px] border border-[#eaf3fe] rounded-lg text-[#185294] bg-[#eaf3fe] text-[13px]">
                        {skill.skillName}
                      </div>
                    ))}
                  </div>
                ) : null}

                {job.jdSummary ? (
                  <p className="text-[#475467] mt-8 text-base ">
                    {job.jdSummary}
                  </p>
                ) : null}

                <div className="flex items-center gap-3 md:gap-6 mt-10 flex-wrap ">
                  <div className="flex items-center gap-[6px]">
                    <Icon type="job-section-jobLocation" />
                    <div className="text-[#475467] text-sm">
                      {job.remote ? "Remote" : job.jobLocation}
                    </div>
                  </div>
                  <div className="flex items-center gap-[6px]">
                    <Icon type="job-section-jobType" />
                    <div className="text-[#475467] text-sm">
                      {job.jobType
                        .split(" ")
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ")}
                    </div>
                  </div>
                  <div className="flex items-center gap-[6px]">
                    <div className="text-[#475467] text-sm">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="19"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#98A2B3"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="lucide lucide-landmark"
                      >
                        <line x1="3" x2="21" y1="22" y2="22" />
                        <line x1="6" x2="6" y1="18" y2="11" />
                        <line x1="10" x2="10" y1="18" y2="11" />
                        <line x1="14" x2="14" y1="18" y2="11" />
                        <line x1="18" x2="18" y1="18" y2="11" />
                        <polygon points="12 2 20 7 4 7" />
                      </svg>
                    </div>
                    <div className="text-[#475467] text-sm">
                      {job.currencyType} {job.minSalary} - {job.maxSalary}{" "}
                      <span>per year</span>
                    </div>
                  </div>
                  <div className="flex items-center gap-[6px]">
                    <div className="text-[#475467] text-sm">
                      Min Experience:
                    </div>
                    <div className="text-[#475467] text-sm">
                      {job.minExperience}+ years
                    </div>
                  </div>

                  <div className="text-[#475467] text-sm ml-auto">
                    Closing Date:{" "}
                    <span className="font-semibold">
                      {job.preferredJoiningDate
                        ? converToReadableDate(job.preferredJoiningDate)
                        : "NA"}
                    </span>
                  </div>
                </div>
              </Card>
              {job?.externalJobNotificationLink && (
                <div className="mt-7 text-center text-[#404d5f] font-semibold">
                  Notification/Reference link:{" "}
                  <a
                    className="text-[#206DC5] break-all"
                    target="_blank"
                    rel="noreferrer"
                    href={job?.externalJobNotificationLink}
                  >
                    {job?.externalJobNotificationLink}
                  </a>
                </div>
              )}

              <div ref={cardRef} className="mb-12">
                <div className="mt-6 mb-3 yellow-text font-bold text-[2rem] text-center">
                  Apply here
                </div>
                <Card className="rounded-2xl shadow  font-[Inter] border-4 border-blue-400  w-[95%] lg:w-[70%] mt-5 mx-auto">
                  <ResumeUploadModal
                    isModalVisible={isModalVisible}
                    handleCancel={handleCancel}
                    jobId={job.jdIdentifier}
                    broadcastdata={job?.broadcastingData}
                    jobData={job}
                  />
                </Card>
              </div>
              {/* )} */}
              <Card className="rounded-2xl shadow-sm font-[Inter] border border-[#e3e3e6] w-[95%] lg:w-[70%] mt-5 mx-auto">
                <div className="text-lg font-semibold">Job Description</div>
                <Divider className="" />
                <div className="h-[600px] overflow-y-auto">
                  <div
                    className="flex justify-end"
                    style={{
                      padding: "10px",
                      position: "sticky",
                      top: 0,
                      zIndex: 1,
                      background: "white",
                    }}
                  >
                    <div className="">
                      <span className="mr-5 ml-0">Pages: {pdfPages}</span>
                      <button
                        onClick={handleZoomIn}
                        disabled={zoom > 1.8 ? true : false}
                        style={{
                          marginRight: "8px",
                          border: "none",
                        }}
                      >
                        <PlusCircleOutlined
                          className={`${
                            zoom > 1.8 ? "text-[#555555]" : "text-[black]"
                          } text-[18px]`}
                        />
                      </button>
                      <button
                        onClick={handleZoomOut}
                        disabled={zoom < 0.5 ? true : false}
                        style={{
                          border: "none",
                        }}
                      >
                        <MinusCircleOutlined
                          className={`${
                            zoom < 0.5 ? "text-[#555555]" : "text-[black]"
                          } text-[18px]`}
                        />{" "}
                      </button>
                      <p
                        className="text-center ml-4 mr-0 p-0 "
                        style={{
                          borderBottom: "1px solid black",
                          display: "inline-block",
                          width: "40px",
                        }}
                      >
                        {Math.round(zoom * 100)}%
                      </p>
                    </div>
                  </div>
                  <PdfViewer
                    url={job.preSignedUrl}
                    zoom={zoom}
                    setPdfPages={setPdfPages}
                  />
                </div>
              </Card>
            </>
          ) : (
            <div className="mt-8">
              <Lottie
                className="m-auto w-[70%] h-[250px]"
                animationData={jdNotFound}
                loop={true}
                autoplay={true}
              />
              <div className="text-center text-[#475467] text-base">
                Invalid Job ID or Job is not broadcasted.
              </div>
            </div>
          )}
        </Spin>
        {isModalVisible && (
          <ResumeUploadModal
            isModalVisible={isModalVisible}
            handleCancel={handleCancel}
            jobId={job.jdIdentifier}
            broadcastdata={job?.broadcastingData}
          />
        )}
      </div>
    </>
  );
}

export default ViewJobLandingPage;
