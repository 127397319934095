let baseUrl =
  window.location.hostname == "localhost"
    ? "https://platform.dev.hireplusplus.com"
    : window.location.origin;

let parserBaseUrl =
  window.location.hostname === "localhost" ||
  window.location.hostname.includes("dev")
    ? "https://parser-dev.hireplusplus.com"
    : "https://parser.hireplusplus.com";

export const contactApi = `${baseUrl}/communication/api/v1/contact/sendContactUsEmail`;
export const recaptchaApi = `${baseUrl}/accountmanager/api/v1/login/verifyCaptcha?response=`;
export const getJobsApi = `${baseUrl}/aimatcher/api/v1/openpool/getOpenJobs`;
export const getJobsCountApi = `${baseUrl}/aimatcher/api/v1/openpool/getOpenJobsCount`;
export const resumeUploadApi = `${baseUrl}/aimatcher/api/v1/openpool/addCandidate`;
export const resumeUploadJdApi = `${baseUrl}/aimatcher/api/v1/openpool/applyForJob`;
export const createOrderApi = `${baseUrl}/aimatcher/api/v1/jd/createCandidateJobApplicationOrder`;
export const parserJdUploadApi = `${baseUrl}/aimatcher/api/v1/parser/uploadJd`;
export const parserResumeUploadApi = `${baseUrl}/aimatcher/api/v1/parser/uploadResume`;
export const subscribeApi = `${baseUrl}/communication/api/v1/contact/mailingList/subscribe`;
export const getBroadcastJdData = `${baseUrl}/aimatcher/api/v1/openpool/getBroadcastJdData`;

export const aiParserResumeFilePostApi = `${parserBaseUrl}/parser/api/v1/resume/parse/try`;
export const aiParserJdFilePostApi = `${parserBaseUrl}/parser/api/v1/jd/parse/try`;

export const aiParserResumeLandingPostApi = `${parserBaseUrl}/parser/api/v1/resume/parse/landingPage`;
export const aiParserJdLandingPostApi = `${parserBaseUrl}/parser/api/v1/jd/parse/landingPage`;
