import React from "react";
import "../../styles/landingPage/heroSection.css";
import { Button, Carousel } from "antd";
import dot from "../../assets/logo/Dot.svg";
import heroImage from "../../assets/images/hero-image.png";
import { Link } from "react-router-dom";
import VideoPlayer from "../../components/landingPage/HeroVideoComponent";

function HeroSection({ currLangJSON }) {
  return (
    <div className="hero-section flex flex-col lg:flex-row ">
      <div
        className="flex flex-col items-start gap-4 basis-1/2"
        data-aos="slide-right"
        data-aos-duration="1000"
        data-aos-delay="50"
        data-aos-once={true}
        data-aos-easing="ease"
      >
        <Link to="/jobs?heroRedirect=true">
          <div className="hiring-banner">
            <img src={dot} alt="dot" className="mt-[2px]" />
            {currLangJSON.bannerText}
          </div>
        </Link>

        <p
          className="text-4xl lg:text-[2.5rem] leading-[3rem] font-bold"
          dangerouslySetInnerHTML={{ __html: currLangJSON.mainHeading }}
        />
        <p className="text-[1.1rem] text-white tracking-wide font-bold mt-1">
          {currLangJSON.subHeading}
        </p>
        <p
          className="text-[1.07rem] text-bodyText"
          dangerouslySetInnerHTML={{ __html: currLangJSON.description }}
        />
        <div className="mt-8 flex md:justify-start justify-center w-full gap-4">
          <Link to="/schedule-demo">
            <Button
              className="font-semibold text-sm text-[#101828]"
              size="large"
            >
              {currLangJSON.buttonScheduleDemo}
            </Button>
          </Link>
          <Link to="/contact-us">
            <Button
              className="font-semibold text-sm bg-[#206DC5] text-[#FCFCFD]"
              size="large"
              style={{ outline: "none", border: "none" }}
            >
              {currLangJSON.buttonSignUp}
            </Button>
          </Link>
        </div>
      </div>
      <div
        className="basis-1/2 flex flex-col  hero-right-sec  gap-4"
        data-aos="slide-left"
        data-aos-duration="1000"
        data-aos-delay="50"
        data-aos-once={true}
        data-aos-easing="ease"
      >
        <VideoPlayer
          src={
            "https://hireplusplus-public-de.s3.eu-central-1.amazonaws.com/LandidngPageVideo.mp4"
          }
          type="video/mp4"
          poster={heroImage}
          width="100%"
          height="auto"
        />
        <div className=" w-[80%] ml-0 ">
          <div
            className="text-lg text-center"
            dangerouslySetInnerHTML={{ __html: currLangJSON.metricsHeading }}
          />
          <div className="max-w-[440px] text-center m-auto">
            <Carousel autoplay className="pb-3 ">
              <div className="w-full ">
                <div className="flex justify-center w-[100%] 2xl:w-[100%] mt-1 ">
                  {currLangJSON?.metrics?.map((metric, index) => (
                    <div
                      key={index}
                      className={`basis-1/2 text-center ${
                        index === 0 ? "border-e-2" : ""
                      }  pb-[2px] px-1`}
                    >
                      <div className="text-orangish text-xl 2xl:text-[26px] xl:leading-8 font-semibold">
                        {metric.value}
                      </div>
                      <div className="text-xs text-white">
                        from{" "}
                        <span className="text-error">
                          {metric.previousValue}
                        </span>
                      </div>
                      <div className="text-[10px] mt-2 text-white">
                        {metric.description}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="w-100 ">
                <div className="flex justify-center w-[100%] 2xl:w-[100%] mt-1 ">
                  {currLangJSON?.metrics2?.map((metric, index) => (
                    <div
                      key={index}
                      className={`basis-1/2 text-center ${
                        index === 0 ? "border-e-2" : ""
                      }  pb-[2px] px-1`}
                    >
                      <div className="text-orangish text-xl 2xl:text-[26px] xl:leading-8 font-semibold">
                        {metric.value}
                      </div>
                      <div className="text-xs text-white">
                        from{" "}
                        <span className="text-error">
                          {metric.previousValue}
                        </span>
                      </div>
                      <div className="text-[10px] mt-2 text-white">
                        {metric.description}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="w-100 ">
                <div className="flex justify-center w-[100%] 2xl:w-[100%] mt-1 ">
                  {currLangJSON?.metrics3?.map((metric, index) => (
                    <div
                      key={index}
                      className={`basis-1/2 text-center ${
                        index === 0 ? "border-e-2" : ""
                      }  pb-[2px] px-1`}
                    >
                      <div className="text-orangish text-xl 2xl:text-[26px] xl:leading-8 font-semibold">
                        {metric.value}
                      </div>
                      <div className="text-xs text-white">
                        from{" "}
                        <span className="text-error">
                          {metric.previousValue}
                        </span>
                      </div>
                      <div className="text-[10px] mt-2 text-white">
                        {metric.description}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
