import React from "react";
import "../../styles/mayaPage/gridSection.css";
import service1 from "../../assets/mayaPage/services-1.png";
import service2 from "../../assets/mayaPage/services-2.png";
import service3 from "../../assets/mayaPage/services-3.png";
import service4 from "../../assets/mayaPage/services-4.png";
import service5 from "../../assets/mayaPage/services-5.png";
import service6 from "../../assets/mayaPage/services-6.png";

function GridSection() {
  return (
    <div className="px-3 sm:px-8 xl:px-[5.9rem] pt-5 pb-12 max-w-[1700px] mx-auto">
      <div className="text-[rgb(32,109,197)] font-semibold text-[1.2rem] text-center">
        Our Services
      </div>
      <section className="grid-container mt-[1.5rem] grid grid-cols-1 xl2:grid-cols-3 grid-rows-2 gap-x-[1.4rem] gap-y-[1.4rem] xl:gap-x-[1.9rem] xl:gap-y-[1.5rem] text-center xl2:text-left">
        <div className="xl2:col-span-2 px-7 py-[1.9rem] md:px-11 md:py-8  rounded-[20px] bg-[#F2F8FE] border border-[#ccdef5] shadow flex gap-[4.8rem] justify-between hover:scale-[1.03] transition-all duration-300">
          <div className="flex-1 flex flex-col gap-4">
            <div className="text-[#101828] font-bold text-[1.6rem] leading-[2.15rem]">
              Simultaneous Multi-Candidate Interviews
            </div>
            <div className="text-[#475467] text-[.96rem] w-[100%] mx-auto xl2:mx-0 xl2:w-[83%]">
              Efficiently assess multiple candidates in real-time with
              simultaneous interviews. This approach streamlines the hiring
              process, allowing for consistent evaluation across a group while
              saving time and resources. Perfect for high-volume recruitment or
              comparing skill sets in dynamic, live sessions.
            </div>
          </div>
          <div className="img-container max-w-[250px] max-h-[295px]">
            <img className="w-full h-full" src={service1} alt="service-img" />
          </div>
        </div>
        <div className="xl2:row-span-2 px-7 py-[1.9rem] md:px-11 md:py-8  rounded-[20px] flex flex-col gap-[2rem] justify-between items-center bg-[#F2F8FE] border border-[#ccdef5] shadow hover:scale-[1.03] transition-all duration-300">
          <div className="flex-1 flex flex-col gap-4">
            <div className="text-[#101828] font-bold text-[1.6rem] leading-[2.15rem]">
              Detailed Analytics and Reporting
            </div>
            <div className="text-[#475467] text-[.96rem] w-[100%] mx-auto xl2:mx-0 xl2:w-[95%]">
              Gain valuable insights with comprehensive analytics and reporting
              tools. Track candidate performance, identify trends, and make
              data-driven hiring decisions with ease. Our detailed reports
              ensure you have all the information needed for accurate and
              effective recruitment assessments.
            </div>
          </div>
          <div className="img-container w-full max-h-[278px]">
            <img className="w-full h-full" src={service2} alt="service-img" />
          </div>
        </div>
        <div className="xl2:col-span-2 px-7 py-[1.9rem] md:px-11 md:py-8 rounded-[20px] bg-[#F2F8FE] border border-[#ccdef5] shadow flex gap-[4rem] justify-between hover:scale-[1.03] transition-all duration-300">
          <div className="img-container max-w-[250px] max-h-[295px]">
            <img className="w-full h-full" src={service3} alt="service-img" />
          </div>
          <div className="flex-1 flex flex-col gap-4">
            <div className="text-[#101828] font-bold text-[1.6rem] leading-[2.15rem]">
              Industry Agnostic
            </div>
            <div className="text-[#475467] text-[.96rem] w-[100%] mx-auto xl2:mx-0 xl2:w-[78%]">
              Easily adaptable for any industry, our solution is designed to
              meet the unique needs of diverse sectors. Whether in tech,
              healthcare, finance, or retail, this tool provides versatile
              features to streamline recruitment across all fields. Empower your
              hiring process with a flexible, industry-neutral approach.
            </div>
          </div>
        </div>
      </section>
      <section className="grid-container mt-[1.5rem] grid grid-cols-1 xl2:grid-cols-3 lg:grid-rows-2 gap-x-[1.4rem] gap-y-[1.4rem] xl:gap-x-[1.9rem] xl:gap-y-[1.5rem] text-center xl2:text-left">
        <div className="xl2:row-span-2 px-7 py-[1.9rem] md:px-11 md:py-8  rounded-[20px] flex flex-col gap-[2rem] justify-between items-center bg-[#F2F8FE] border border-[#ccdef5] shadow hover:scale-[1.03] transition-all duration-300">
          <div className="flex-1 flex flex-col gap-4">
            <div className="text-[#101828] font-bold text-[1.6rem] leading-[2.15rem]">
              24/7 Availability
            </div>
            <div className="text-[#475467] text-[.96rem] w-[100%] mx-auto xl2:mx-0 xl2:w-[95%]">
              Ensure seamless recruitment with round-the-clock availability,
              accommodating candidates across time zones and schedules. Our
              platform is accessible 24/7, allowing both interviewers and
              applicants the flexibility to connect at convenient times. Perfect
              for global teams and diverse hiring needs, it supports a
              consistent and efficient hiring process any time of day.
            </div>
          </div>
          <div className="img-container w-full max-h-[272px]">
            <img className="w-full h-full" src={service4} alt="service-img" />
          </div>
        </div>
        <div className="xl2:col-span-2 px-7 py-[1.9rem] md:px-11 md:py-8 rounded-[20px] bg-[#F2F8FE] border border-[#ccdef5] shadow flex gap-[4rem] justify-between hover:scale-[1.03] transition-all duration-300">
          <div className="img-container max-w-[250px] max-h-[295px]">
            <img className="w-full h-full" src={service5} alt="service-img" />
          </div>
          <div className="flex-1 flex flex-col gap-4">
            <div className="text-[#101828] font-bold text-[1.6rem] leading-[2.15rem]">
              Instant Feedback
            </div>
            <div className="text-[#475467] text-[.96rem] w-[100%] mx-auto xl2:mx-0 xl2:w-[82%]">
              Provide candidates with immediate insights through instant
              feedback, enhancing engagement and transparency in the hiring
              process. Real-time responses enable faster decision-making,
              helping candidates and recruiters move forward with clarity and
              confidence.
            </div>
          </div>
        </div>
        <div className="xl2:col-span-2 px-7 py-[1.9rem] md:px-11 md:py-8  rounded-[20px] bg-[#F2F8FE] border border-[#ccdef5] shadow flex gap-[4.8rem] justify-between hover:scale-[1.03] transition-all duration-300">
          <div className="flex-1 flex flex-col gap-4">
            <div className="text-[#101828] font-bold text-[1.6rem] leading-[2.15rem]">
              Customizable Assessments
            </div>
            <div className="text-[#475467] text-[.96rem] w-[100%] mx-auto xl2:mx-0 xl2:w-[82%]">
              Tailor assessments to match the specific skills and competencies
              required for each role. Our customizable tools let you design
              evaluations that align with your organization’s unique standards,
              ensuring a precise and relevant candidate assessment every time.
            </div>
          </div>
          <div className="img-container max-w-[250px] max-h-[295px]">
            <img className="w-full h-full" src={service6} alt="service-img" />
          </div>
        </div>
      </section>
    </div>
  );
}

export default GridSection;
