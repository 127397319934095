import Lottie from "lottie-react";
import React from "react";
import "../../styles/mayaPage/heroSection.css";
import emptyAnimation from "../../assets/lotties/empty-lottie.json";
import { Link } from "react-router-dom";
import { Button } from "antd";

function HeroSection() {
  return (
    <div className="maya-hero-section m-auto flex flex-col lg:flex-row lg:justify-between items-center gap-x-3 gap-y-6">
      <div className="text-white w-full lg:w-[50%] px-0 lg:px-[25px] xl:px-[55px]">
        <div className="text-4xl lg:text-[2.5rem] leading-[3rem] text-[#FCFCFD] font-bold">
          Meet Maya:{" "}
          <span className="text-[#F79009]">Your 24/7 AI Recruiter</span> Agent
        </div>
        <div className="text-[1.13rem] mt-[1.1rem] mb-[2rem] max-w-[500px]">
          Revolutionize your hiring process with AI-powered interviews,
          assessments, and insights. 
        </div>
        <div className="flex gap-2 sm:gap-[.85rem]">
          <Button
            className="text-[.85rem] sm:text-[.94rem] font-semibold text-[#101828]"
            size="large"
          >
            <Link to="/schedule-demo">Schedule Demo</Link>
          </Button>
          <Button
            className="text-[.85rem] sm:text-[.94rem] font-semibold bg-[#206DC5] text-[#FCFCFD]"
            size="large"
            style={{ outline: "none", border: "none" }}
          >
            <Link to="/contact-us">Sign up for Free Trial</Link>
          </Button>
        </div>
      </div>
      <div className="w-full lg:w-[40%]">
        <Lottie
          animationData={emptyAnimation}
          loop={true}
          autoplay={true}
          style={{ maxWidth: "470px", margin: "auto" }}
        />
      </div>
    </div>
  );
}

export default HeroSection;
