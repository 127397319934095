import { Modal } from "antd";
import { TriangleAlert } from "lucide-react";
import React from "react";

function PaymentOrderModal({ orderModal, setOrderModal }) {
  return (
    <Modal
      title={
        <p className="text-[#101828] text-[1.3rem] font-semibold text-center">
          Payment Gateway Failure
        </p>
      }
      footer={[null]}
      open={orderModal}
      onCancel={() => setOrderModal(false)}
      centered
      width={500}
    >
      <div className="flex justify-center items-center mt-4">
        <TriangleAlert size={56} color="#ef4444" />
        {/* <svg
          width={"64"}
          height={"64"}
          viewBox="0 0 48 49"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 24.5C0 11.2452 10.7452 0.5 24 0.5C37.2548 0.5 48 11.2452 48 24.5C48 37.7548 37.2548 48.5 24 48.5C10.7452 48.5 0 37.7548 0 24.5Z"
            fill="#FEE4E2"
          />
          <path
            d="M23.9998 20.9963V24.9963M23.9998 28.9963H24.0098M22.6151 15.888L14.3902 30.0947C13.934 30.8827 13.7059 31.2767 13.7396 31.6C13.769 31.8821 13.9168 32.1384 14.1461 32.3051C14.4091 32.4963 14.8643 32.4963 15.7749 32.4963H32.2246C33.1352 32.4963 33.5904 32.4963 33.8534 32.3051C34.0827 32.1384 34.2305 31.8821 34.2599 31.6C34.2936 31.2767 34.0655 30.8827 33.6093 30.0947L25.3844 15.888C24.9299 15.1029 24.7026 14.7103 24.4061 14.5784C24.1474 14.4634 23.8521 14.4634 23.5935 14.5784C23.2969 14.7103 23.0696 15.1029 22.6151 15.888Z"
            stroke="#D92D20"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg> */}
      </div>
      <div className="text-[.98rem] text-center mt-[.8rem]">{orderModal}</div>
      <div className="text-[.98rem] text-center">
        Please retry in some time.
      </div>
    </Modal>
  );
}

export default PaymentOrderModal;
