import React from "react";
import "../../styles/jobsPage/jobsHeroSection.css";
import { Col, Row, Button } from "antd";
import { Link } from "react-router-dom";
import "../../styles/aiParser/aiMain.css";

import LandingPageImg from "../../assets/image/aiParserHero.png";
import liveIcon from "../../assets/image/aiParserbtnIcon.png";

function AIParserHeroSection({ currLangJSON }) {
  const scrollToDemo = () => {
    document
      .getElementById("mainParserContainer")
      .scrollIntoView({ behavior: "smooth" });
  };

  const heroData = currLangJSON.aiParser.heroSection;

  return (
    <>
      <Row className="ai-parser-hero-section gap-2">
        <Col md={24} lg={11} className="m-auto">
          <div className="text-[#fff] font-[600] mb-[.85rem] text-base">
            <span>{heroData.tagline}</span>
          </div>
          <span className="text-white font-[600] text-[2.5rem] leading-[50px]  mb-0">
            {heroData.mainHeading} {/* <br className="p-0 m-0" /> */}
            <span className="text-orangish flex-inline font-[600] mt-0  text-[2.5rem] break-words">
              {heroData.highlight}
            </span>
          </span>
          <div className="text-[white] mt-5 text-[1.1rem] font-[400]">
            {heroData.description}
          </div>
          <div className="mt-8 flex justify-start w-full gap-4">
            <button
              className="flex items-center font-semibold text-sm text-[#101828] bg-white px-3 py-2 rounded-lg"
              // size="large"
              onClick={scrollToDemo}
            >
              <img
                src={liveIcon}
                style={{
                  display: "inline-block",
                  marginRight: "6px",
                  outline: "none",
                  border: "none",
                }}
                alt="Live Trial Icon"
              />
              {heroData.liveTrialButton}
            </button>

            <Link to="/contact-us">
              <button
                className="font-semibold text-sm bg-[#206DC5] text-[#FCFCFD] px-3 py-3  rounded-lg"
                // size="large"
                // style={{ outline: "none", border: "none" }}
              >
                {heroData.contactSalesButton}
              </button>
            </Link>
          </div>
        </Col>
        <Col md={24} lg={11} className="job-right-sec m-auto">
          <img src={LandingPageImg} alt="AI Parser Hero" />
        </Col>
      </Row>
      <Row style={{ marginTop: "30px" }} className="px-1">
        <Col md={20} className="m-auto">
          <p className="use-cases-sub-heading mb-2">
            Why Switch to HirePlusPlus?
          </p>
          <div className="font-[600] text-4xl text-center mb-2">
            Bring down your Costs while{" "}
            <span className=" text-[#DC6803]">boosting Accuracy</span>
          </div>
          <div className="use-cases-description  text-[#475467] text-center">
            Transform your data processing with HirePlusPlus. Experience
            cutting-edge accuracy, seamless integration, and significant cost
            savings, all powered by continuously improving AI technology,
            disrupting parsing tech powered by rules-based engines and keyword
            matching.
          </div>
        
        </Col>
      </Row>
    </>
  );
}

export default AIParserHeroSection;
