import React from "react";
import Icon from "../../assets/icon/Icons";

function SecondSection() {
  return (
    <div
      className="text-center py-12 px-4"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-once={true}
      data-aos-easing="ease"
    >
      <div className="font-semibold text-[#206DC5] text-[1.2rem] leading-[1.65rem]">
        Your Ultimate AI Hiring Partner:{" "}
        <span className="text-[#F79009]">
          Endless Possibilities, One Solution
        </span>
      </div>
      <div className="text-[#101828] text-[.96rem] mt-[.7rem] w-[92%] sm:w-[70%] m-auto">
        “Revolutionize your hiring process with Maya, the advanced AI recruiter
        at the heart of HirePlusPlus. Maya goes beyond traditional chatbots,
        offering a sophisticated AI-driven system that transforms every stage of
        recruitment.” 
      </div>
      <section
        className="mt-[3.2rem]"
        data-aos="zoom-in"
        data-aos-duration="1000"
        data-aos-once={true}
        data-aos-easing="ease"
      >
        <div className="text-[#206DC5] font-semibold text-[1.2rem]">
          Our Features
        </div>
        <div className="font-bold text-[#F79009] text-[1.6rem] mt-[.3rem] leading-[2.2rem]">
          Versatile Hiring <span className="text-[#101828]">Capabilities</span>
        </div>
        <div
          className="w-[95%] lg:w-[85%] xl:w-[75%] mx-auto mt-[1.8rem] flex flex-col md:flex-row justify-center items-center gap-x-5 gap-y-6"
          data-aos="zoom-in"
          data-aos-duration="1000"
          data-aos-once={true}
          data-aos-easing="ease"
        >
          <div className="w-full md:w-[31%] flex flex-col gap-1 justify-center items-center">
            <div>
              <Icon type="maya-capbilities-logo" />
            </div>
            <div className="font-semibold text-[#101828]">
              Campus Placements
            </div>
            <div className="text-[#475467] w-[85%] mx-auto text-[.94rem]">
              Efficiently screen and evaluate fresh talent at scale.
            </div>
          </div>
          <div className="w-full md:w-[31%] flex flex-col gap-1 justify-center items-center">
            <div>
              <Icon type="maya-capbilities-logo" />
            </div>
            <div className="font-semibold text-[#101828]">Lateral Hiring</div>
            <div className="text-[#475467] w-[85%] mx-auto text-[.94rem]">
              Assess experienced professionals with role-specific evaluations.
            </div>
          </div>
          <div className="w-full md:w-[31%] flex flex-col gap-1 justify-center items-center">
            <div>
              <Icon type="maya-capbilities-logo" />
            </div>
            <div className="font-semibold text-[#101828]">
              Management Hiring
            </div>
            <div className="text-[#475467] w-[85%] mx-auto text-[.94rem]">
              Conduct in-depth interviews for leadership positions. 
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SecondSection;
