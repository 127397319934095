import { notification } from "antd";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

const loadScript = (src) =>
  new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      // console.log("razorpay loaded successfully");
      resolve(true);
    };
    script.onerror = () => {
      // console.log("error in loading razorpay");
      resolve(false);
    };
    document.body.appendChild(script);
  });

const RazorpayCheckout = ({
  setRazorpayModal,
  apiKey,
  selectedData,
  orderData,
  setPaymentSuccess,
}) => {
  const paymentId = useRef(null);
  const paymentMethod = useRef(null);
  // console.log(atob(apiKey));

  // To load razorpay checkout modal script.
  const displayRazorpay = async (options) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      // console.log("Razorpay SDK failed to load. Are you online?");
      return;
    }
    // All information is loaded in options which we will discuss later.
    const rzp1 = new window.Razorpay(options);

    // If you want to retreive the chosen payment method.
    rzp1.on("payment.submit", (response) => {
      paymentMethod.current = response.method;
      // console.log("Success", response);
    });

    // To get payment id in case of failed transaction.
    rzp1.on("payment.failed", (response) => {
      paymentId.current = response.error.metadata.payment_id;
      // console.log("Failed", response);
    });

    // to open razorpay checkout modal.
    rzp1.open();
  };

  //   const handleUpdatePayment = (response) => {
  //     console.log("succeeded", response);
  //     let url = "";
  //     let body = {};
  //     if (modalType === "Top-Up") {
  //       url = updateTopUpPaymentStatus;
  //       body = {
  //         userIdentifier: userDetail?.userIdentifier || null,
  //         paymentId: response.razorpay_payment_id,
  //         orderId: response.razorpay_order_id,
  //         razorpaySignature: response.razorpay_signature,
  //       };
  //     } else {
  //       url = updateSubscriptionPaymentApi;
  //       body = {
  //         userIdentifier: userDetail?.userIdentifier || null,
  //         paymentId: response.razorpay_payment_id,
  //         subscriptionId: response.razorpay_subscription_id,
  //         razorpaySignature: response.razorpay_signature,
  //       };
  //     }
  //     WebUtils.httpOperations(url, body, "POST")
  //       .then((result) => {
  //         console.log(result);
  //         if (result.data.resultStatusInfo.resultCode === "Success") {
  //           console.log(result.data);
  //           setLoading(false);
  //           setPaymentModalVisible(true);
  //         } else {
  //           console.log("Error");
  //           setLoading(false);
  //           notification.error({
  //             message: "Something went wrong!",
  //             placement: "top",
  //             showProgress: true,
  //           });
  //         }
  //       })
  //       .catch((err) => {
  //         setLoading(false);
  //         console.log(err);
  //         notification.error({
  //           message: "Something went wrong!",
  //           placement: "top",
  //           showProgress: true,
  //         });
  //       });
  //   };

  let options = {
    key: atob(apiKey),
    // amount: selectedData?.amount * 100,
    // currency: selectedData?.currency,
    // order_id: "order_Pk5GTlXw03eyLU",
    order_id: orderData,
    // prefill: {
    //   name: `${userData?.profileInfo?.firstName} ${userData?.profileInfo?.lastName}`,
    //   email: userData?.profileInfo?.email,
    //   contact: `${userData?.profileInfo?.countryCode}${userData?.profileInfo?.mobileNumber}`,
    // },

    // This handler menthod is always executed in case of succeeded payment
    handler: (response) => {
      // console.log(response);
      setRazorpayModal(false);
      setPaymentSuccess(response);

      // setShowPricingModal(false);
      // setLoading(true);
      // setTimeout(() => {
      //   handleUpdatePayment(response);
      // }, [5000]);
    },
    modal: {
      confirm_close: true, // this is set to true, if we want confirmation when clicked on cross button.
      // This function is executed when checkout modal is closed
      // There can be 3 reasons when this modal is closed.
      ondismiss: async (reason) => {
        const {
          reason: paymentReason,
          field,
          step,
          code,
        } = reason && reason.error ? reason.error : {};
        // console.log("Closing");
        setRazorpayModal(false);
      },
    },
    // This property allows to enble/disable retries.
    // This is enabled true by default.
    retry: {
      enabled: true,
    },
    // timeout: 900,
    theme: {
      color: "", // Custom color for your checkout modal.
    },
  };

  useEffect(() => {
    // console.log("in razorpay");
    displayRazorpay(options);
  }, []);

  return null;
};

export default RazorpayCheckout;
